import { createTheme, ThemeProvider } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./assets/css/global.css";
import "assets/animations/animations.css";
import { setupStore } from "./redux/store";
import { I18nextProvider } from "react-i18next";
import i18n from "./locale/i18n";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const store = setupStore();
export default store;

const theme = createTheme({
  palette: {
    primary: {
      light: "rgb(30 64 175)",
      main: "rgb(30 64 175)",
      dark: "rgb(30 58 138)",
      contrastText: "#fff",
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "outlined" },
          style: {
            border: "1px solid rgb(30 64 175)",
          },
        },
      ],
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <I18nextProvider i18n={i18n}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <BrowserRouter >
              <App />
            </BrowserRouter>
          </LocalizationProvider>
        </I18nextProvider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
