export const dataPhones: IComponents.IDataPhones[] = [
    {
        id: 49,
        name: 'ashgabat',
        number: ['+993 12-96-46-41']
    },
    {
        id: 50,
        name: 'mary',
        number: ['+993 522-7-08-29', '+993 522-7-08-62']
    },
    {
        id: 51,
        name: 'balkan',
        number: ['+993 222-2-33-88', '+993 222-2-33-66']
    },
    {
        id: 52,
        name: 'lebap',
        number: ['+993 422-4-35-20', '+993 422-4-35-24', '+993 422-4-35-21', '+993 422-4-35-28']
    },
    {
        id: 53,
        name: 'dasoguz',
        number: ['+993 12-96-46-06', '+993 12-96-46-87', '+993 322-2-42-91', '+993 322-2-42-84']
    },
];